@charset "utf-8";

@import "minimal-mistakes/skins/dark"; // skin
@import "minimal-mistakes"; // main partials

#main {
    max-width: 1920px !important;
}
  
.breadcrumbs {
    max-width: 1920px !important;
}

.masthead__inner-wrap{
    max-width: 1920px !important;
}

.grid__item .archive__item-teaser {
    max-height: 256px !important;
    border: 1px solid $border-color
}

.feature__wrapper .archive__item-title {
    font-size: 30px;
}